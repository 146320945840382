.root {
  border: 1px solid rgb(var(--ion-color-primary-rgb), 0.2);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
}

.root:focus-within {
  box-shadow: 0 1px 4px rgb(var(--ion-color-primary-rgb), 0.3);
  border: 1px solid rgb(var(--cha-primary-color-rgb), 0.4);
}

:global(.dark) {
  .root {
    border: 1px solid rgb(255, 255, 255, 0.2);
  }

  .root:focus-within {
    box-shadow: 0 1px 4px rgb(255, 255, 255, 0.2);
  }
}

.root ion-item {
  --padding-start: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-end: 8px;
  --min-height: 0px;
}

.second {
  margin-right: 5px;
  border-radius: 5px 0 0 5px;
  padding: 5px 5px 5px 10px;
}

.cha-label,
.cha-sub-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cha-label {
  font-weight: 400;
}

.cha-sub-label {
  font-size: 12px;
  color: var(--ion-color-medium);
  margin: 2px 0;
}


.root .input {

  line-height: 1.5em;
}

.root .input {
  text-align: right;
}

.input.ts1,
.short.ts1 {
  font-size: 1rem;
}

.input.ts2,
.short.ts2 {
  font-size: 1.25rem;
}


.input.ts3,
.short.ts3 {
  font-size: 1.5rem;
}

.input.ts4,
.short.ts4 {
  font-size: 2rem;
}

.input.ts5,
.short.ts5 {
  font-size: 2.5rem;
}

.input.ts6,
.short.ts6 {
  font-size: 3rem;
}

.short {
  padding: 5px;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background: rgb(var(--ion-color-primary-rgb), 15%); */
  margin-left: 5px;
  color: var(--ion-color-medium);
}

.short.l1 {
  font-size: 1.5rem;
}

.short.l2 {
  font-size: 1.25rem;
}

.short.l3 {
  font-size: 1rem;
}

.short.l4 {
  font-size: 0.875rem;
}

.input-start {
  margin-right: 5px;

}

.input-end {
  margin-left: 0px;
}