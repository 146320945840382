.converterLabel {
  margin: 5px 0 10px 0;
  font-size: 1rem;
  font-weight: 700;
}

.titleSection {
  margin: 25px 10px 15px 10px;
}

.titleSection h1 {
  font-weight: 300;
  font-size: 1.5rem;
  margin: 0;
}

.formulaSection {
  font-size: 2rem;
  margin-top: 10px;
}

.page ion-header {
  display: none;
}

:global(.can-go-back) .page {
  .titleSection {
    margin-top: 0;
  }
}

:global(.can-go-back) .page ion-header {
  display: block;
}
