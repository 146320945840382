:root {
  --ion-font-family: "Roboto", "Helvetica Neue", "Segoe UI Variable Display",
    "Segoe UI", sans-serif;
}

body {
  /* background: #fff; */
  --ion-background-color: linear-gradient(
    180deg,
    rgb(var(--ion-color-primary-rgb), 0.05) 18.22%,
    rgb(var(--ion-color-primary-rgb), 0.1) 100%
  );

  --ion-toolbar-background: rgb(var(--ion-color-primary-rgb), 0.05);
  --ion-item-background: rgb(255, 255, 255, 0.9);
}

body.dark {
  /* background: #000; */
  --ion-background-color: linear-gradient(
    180deg,
    rgb(var(--ion-color-primary-rgb), 0.3) 18.22%,
    rgb(var(--ion-color-primary-rgb), 0.1) 100%
  ) !important;
  --ion-toolbar-background: rgb(var(--ion-color-primary-rgb), 0.3) !important;
  --ion-item-background: rgb(var(--ion-color-primary-rgb), 1);
}

.layoutBorder {
  background: rgba(var(--ion-color-primary-rgb), 0.03);
  border: 1px solid rgba(var(--ion-color-primary-rgb), 0.1);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 25px;
}

.dark .layoutBorder {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 25px;
}

ion-menu,
.ion-page {
  background: #fff;
}

.dark ion-menu,
.dark .ion-page {
  background: #000;
}
